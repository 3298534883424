import React, { useState } from "react";
import Swiper from "react-id-swiper";

const TestimonialClient = ({ data }) => {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    autoHeight: true,
    loop: true,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
    },
  };

  return (
    <section id="testimonial2-swiper" className="">
      <div className="container">
        <h1 className="text-center mb-5">Testimonials</h1>
        <Swiper getSwiper={updateSwiper} {...params}>
          {data.length > 0 &&
            data.map((user) => {
              const description = user.message;
              return (
                <div key={user.name}>
                  <div className="row justify-content-sm-center">
                    <div className="col-12">
                      <div className="d-block">
                        <div className="unit1 flex-sm-row justify-content-center">
                          <div className="unit-left d-flex flex-sm-column justify-content-center align-items-center ml-0">
                            {user.logo_link ? (
                              <img
                                className=""
                                height="120"
                                src={user.logo_link}
                                alt={user.client_name}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="unit-body d-flex flex-column justify-content-center align-items-center">
                            <div className="d-block">
                              <div className="h5 text-primary mt-3">
                                {user.client_name}
                              </div>
                            </div>
                            <div className="d-block">
                              <div className="p text-dark">{user.location}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="offset-top-41">
                        <hr className="hr hr-gradient" />
                        <div className=" text-center   font-italic ">
                          <p
                            className="text-italic "
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        </div>
                        <hr className="hr hr-gradient" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Swiper>

        <div id="testmonial-owl-nav">
          <button className="btn btn-primary mr-1" onClick={goPrev}>
            prev
          </button>
          <button className="btn btn-primary ml-1" onClick={goNext}>
            next
          </button>
          {/* 
          <Link className="btn btn-primary mb-0" to="/ofw-testimonials">
            View All
          </Link> */}
        </div>
      </div>
    </section>
  );
};

export default TestimonialClient;
