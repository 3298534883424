import React, { Fragment, useState } from "react";

const Videos = () => {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const videoSwiperParams = {
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    effect: "coverflow",
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
    /*scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            lockClass: 	'swiper-scrollbar-lock'
        },*/
    mousewheel: {
      invert: false,
    },
  };

  return (
    <Fragment>
      <section id="videosSwiper" className=" section bg-gray-light">
        <div className="justify-content-sm-center container">
          <div className=" text-dark text-center mb-5 ">
            <h4>Watch this short video to know quick facts about us.</h4>
          </div>
          <div className="card">
            <iframe
              // className="card-body"
              className="videoframe"
              // style={{ minHeight: 550 }}
              src="https://www.youtube.com/embed/_PrVpIQCDpo?si=CszRkmDXZ-itSIak&amp;controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        {/* <div className="justify-content-sm-center container">
                <Swiper getSwiper={updateSwiper} {...videoSwiperParams}>
                  <article className="post post-modern mb-4 ">
                    <header className="post-media">
                      <div className="embed-responsive embed-custom-16by9">
                        <iframe
                          className="embed-responsive-item"
                          src="https://www.youtube.com/embed/R-m5bc5_n4Q"
                        ></iframe>
                      </div>
                    </header>
                  </article>
                  <article className="post post-modern mb-4 ">
                    <header className="post-media">
                      <div className="embed-responsive embed-custom-16by9">
                        <iframe
                          className="embed-responsive-item"
                          src="https://www.youtube.com/embed/Qh3DTmvZ0gU"
                        ></iframe>
                      </div>
                    </header>
                  </article>
                  <article className="post post-modern mb-4 ">
                    <header className="post-media">
                      <div className="embed-responsive embed-custom-16by9">
                        <iframe
                          className="embed-responsive-item"
                          src="https://www.youtube.com/embed/2c7aa_NBLGk"
                        ></iframe>
                      </div>
                    </header>
                  </article>
                  <article className="post post-modern mb-4 ">
                    <header className="post-media">
                      <div className="embed-responsive embed-custom-16by9">
                        <iframe
                          className="embed-responsive-item"
                          src="https://www.youtube.com/embed/HMzUfajf7EI?wmode=transparent"
                        ></iframe>
                      </div>
                    </header>
                  </article>

                  <article className="post post-modern mb-4 ">
                    <header className="post-media">
                      <div className="embed-responsive embed-custom-16by9">
                        <iframe
                          className="embed-responsive-item"
                          src="https://www.youtube.com/embed/qaVkEHYb6x8?start=47?wmode=transparent"
                        ></iframe>
                      </div>
                    </header>
                  </article>
                </Swiper>
              </div> */}

        {/* <div id="swiper-controls">
          <div id="testmonial-owl-nav">
            <button className="btn btn-primary mr-1" onClick={goPrev}>
              prev
            </button>
            <button className="btn btn-primary ml-1" onClick={goNext}>
              next
            </button>
          </div>
        </div> */}
      </section>
    </Fragment>
  );
};

export default Videos;
