import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as c from "../constant";
import Testimonial from "../component/TestimonialClient";

class TestimonialClients extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch({
      type: c.GET_CLIENT_TESTIMONIAL,
    });
  }

  render() {
    const { testimonial } = this.props;

    return (
      <section
        id="testimonial2-swiper"
        className="section-98 section-md-110 bg-gray-light"
      >
        {testimonial.size > 0 && <Testimonial data={testimonial.toJS()} />}
      </section>
    );
  }
}

const mapStateToProps = (state, routeParams) => {
  const auth = state.auth;
  return {
    testimonial: auth.get("client_testimonials"),
  };
};

export default withRouter(connect(mapStateToProps)(TestimonialClients));
