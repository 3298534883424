import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Swiper from "react-id-swiper/lib";

class HomeMainSlider extends Component {
  render() {
    const params2 = {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next.slide-arrow.right-c-arrow",
        prevEl: ".swiper-button-prev.slide-arrow.left-c-arrow",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
      },

      speed: 1000,
      spaceBetween: 0,
      containerClass: "swiper-wrapper",
    };

    return (
      <Fragment>
        <div className="homefeature">
          <div className="backdrop"></div>
          <div className="hero-swiper">
            <Swiper {...params2}>
              <div
                className="swiper-slide hero-slide2"
                data-swiper-autoplay="5000"
              >
                <div className="hero-text text-extra-big text-white font-weight-bold">
                  <h1>
                    <span className="em">Multi-Awarded</span>
                    <br />
                    <span className="">
                      Trusted by the Philippine Government
                    </span>
                    <br />
                    <span className="">Trusted by Filipinos</span>
                  </h1>

                  <h5 className="pt-3">
                    IPAMS only offers exceptional service to our worldwide
                    clients, and our ethical recruitment practice is trusted by
                    the government and Filipino job seekers.
                  </h5>
                </div>
              </div>
              <div
                className="swiper-slide hero-slide2"
                data-swiper-autoplay="5000"
              >
                <div className="hero-text text-extra-big text-white font-weight-bold">
                  <h1>
                    {" "}
                    <span className="em">Industry Leader</span>{" "}
                    <br className="break-control" />
                    In Philippine Recruitment{" "}
                  </h1>

                  <h5 className="pt-3">
                    We have paved the way to #ethicalrecruitment, garnering the
                    trust and long-time partnership of the best employers across
                    the Americas, Europe, MENA, Asia Pacific, and Oceania.
                  </h5>
                </div>
              </div>

              <div
                className="swiper-slide hero-slide2"
                data-swiper-autoplay="5000"
              >
                <div className="hero-text text-extra-big text-white font-weight-bold">
                  <h1 className="text-white">
                    {" "}
                    More than 50 Years of{" "}
                    <span className="em">#UpliftingLives</span> <br />
                    <span className="">100% No Fees</span> <br />
                  </h1>
                  <h5 className="pt-3">
                    We provide the #BestJobsAbroad available worldwide for
                    Filipinos that are #AbsolutelyZeroFees.{" "}
                  </h5>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { loadingTypes } = state.loading;

  return {
    isLoading: loadingTypes.length > 0,
  };
};

export default withRouter(connect(mapStateToProps)(HomeMainSlider));
