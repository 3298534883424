import React, { useState, useEffect } from "react";
import ipamsmap from "assets/ipams-img/ipams-map.jpg";

const Counter = ({ id, interval, maxCount, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const totalUpdates = (duration * 1000) / interval; // Total number of updates in the duration
    const step = maxCount / totalUpdates; // Increment step based on maxCount and updates

    const timer = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + step;
        if (newCount >= maxCount) {
          clearInterval(timer); // Stop the timer when the max count is reached
          return maxCount;
        }
        return newCount;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [interval, maxCount, duration]);

  return <>{Math.floor(count)}</>;
};

export default function GlobalReach() {
  const duration = 3; // All counters will finish in 3 seconds
  const interval = 10; // Update every 10ms
  return (
    <>
      <section className="clients section bg-white my-5 py-5">
        <div className="container">
          <div className="justify-content-sm-center container"></div>
          <div className="container row p-0">
            <div className="col-md-5 ">
              <div className="mb-5">
                <h3 className="text-primary">
                  We work with the largest and most influential businesses
                  worldwide.
                </h3>
                <h6 className="offset-top-10 text-dark text-justify">
                  We partner with esteemed companies all over the world to
                  recruit the most competent Filipino workers and professionals.
                </h6>
              </div>
              <div className="row">
                <div className="col my-3">
                  <div className="counter-container d-flex flex-row  text-primary">
                    <h3 className=" text-primary display-lead">
                      <Counter
                        id={1}
                        interval={interval}
                        maxCount={63000}
                        duration={duration}
                      />
                    </h3>
                    <h3 className="mt-0 text-primary">+</h3>
                  </div>
                  <h5 className="mt-0">Deployed Workers</h5>
                </div>
                <div className="col my-3">
                  <div className="counter-container d-flex flex-row text-primary">
                    <h3 className=" text-primary">
                      <Counter
                        id={1}
                        interval={interval}
                        maxCount={60}
                        duration={duration}
                      />
                    </h3>
                    <h3 className="mt-0 text-primary">+</h3>
                  </div>
                  <h5 className="mt-0">Countries Deployed</h5>
                </div>
                <div className="col my-3">
                  <div className="counter-container d-flex flex-row  text-primary">
                    <h3 className=" text-primary">
                      <Counter
                        id={1}
                        interval={interval}
                        maxCount={1175}
                        duration={duration}
                      />
                    </h3>
                    <h3 className="mt-0 text-primary">+</h3>
                  </div>
                  <h5 className="mt-0"> Accredited Clients</h5>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <img src={ipamsmap} width="100%" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
