import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
// import trustedbyfil from "assets/inside-page-assets/about-trustedbyfil.png";
// import trustedbygov from "assets/inside-page-assets/about-trustedbygov.png";
// import facility from "assets/inside-page-assets/about-facility.png";
// import leadTech from "assets/inside-page-assets/about-lead-tech.png";
// import multiawards from "assets/inside-page-assets/about-multiawards.png";
// import verifiedemployer from "assets/inside-page-assets/about-verifiedemployer.png";
// import endtoend from "assets/inside-page-assets/about-endtoend.png";
import facilities from "assets/images/whychooseus/facilities.jpg";
import e2erecruit from "assets/images/whychooseus/e2erecruit.jpg";
import multiaward from "assets/images/whychooseus/multiaward.jpg";
import roster from "assets/images/whychooseus/roster.jpg";
import tech from "assets/images/whychooseus/tech.jpg";
import trustbyfil from "assets/images/whychooseus/trustbyfil.jpg";
import trustbygov from "assets/images/whychooseus/trustbygov.jpg";

class WhyPeopleChooseUs extends Component {
  render() {
    return (
      <Fragment>
        <div className="wrapper">
          <div className="card-container">
            <div className="poster">
              <img src={trustbyfil} alt="Location Unknown" />
            </div>
            <div className="details">
              <div className="rating text-center">TRUSTED BY FILIPINOS</div>
              <div className="bg-container">
                <div className="cast">
                  TRUSTED BY FILIPINOS; 100% NO FEE CHARGING COMPANY
                </div>
                <p className="desc">
                  We do not extract placement fees, processing fees, nor salary
                  deductions from all Filipinos. We also ensure our
                  world-renowned clients are the best providers of career
                  employment for the Filipino worker. In return, we choose only
                  the best qualified candidates for these jobs for our esteemed
                  clientele worldwide.
                </p>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="poster">
              <img src={trustbygov} alt="Location Unknown" />
            </div>
            <div className="details">
              <div className="rating text-center">
                TRUSTED BY THE PHILIPPINE GOVERNMENT
              </div>
              <div className="bg-container">
                <div className="cast">
                TRUSTED BY THE PHILIPPINE GOVERNMENT
                </div>
                <p className="desc">
                  IPAMS through the years has been regarded highly by the
                  Philippine Government with its best practices and high
                  standard of professionalism. IPAMS garnered the highest award
                  given to any Philippine Recruitment Agency on April 21, 2010
                  granted by the President of the Philippines. As one of only
                  seven Presidential Awardees in the Philippines we are entitled
                  to additional benefits that can expedite the overall
                  recruitment process by as much as 20%.
                </p>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="poster">
              <img src={facilities} alt="Location Unknown" />
            </div>
            <div className="details">
              <div className="rating text-center">
                BEST-IN-CLASS FACILITIES{" "}
              </div>
              <div className="bg-container">
                <div className="cast">
                  BEST-IN-CLASS FACILITIES FOR SMALL TO LARGE-SCALE RECRUITMENT
                  ACTIVITIES
                </div>
                <p className="desc">
                  We have the right tools and experience to recruit the most
                  qualified candidates should our clients require a few or even
                  thousands of applicants. We understand our clients' needs and
                  conduct our own thorough initial screening to find the right
                  candidates for the job. Our multi-level interview rooms are
                  equipped to handle a large volume of applicants at one time,
                  and we have the best-in-class facilities to hold all your
                  recruitment needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card-container">
            <div className="poster">
              <img src={tech} alt="Location Unknown" />
            </div>
            <div className="details">
              <div className="rating text-center">LEADING TECHNOLOGY</div>
              <div className="bg-container">
                <div className="cast">
                  LEADING TECHNOLOGY & STREAMLINED PROCESS
                </div>
                <p className="desc">
                  Our IPAMS ORION system (Overseas Recruitment Interactive
                  Online Network) allows us to store, sort, and retrieve
                  thousands of resumes and organizes our candidates in every
                  step of the recruitment process. From the time Job Seekers
                  apply online, to the time they are selected, screened,
                  processed, and deployed, our system tracks each applicant to
                  be processed for each recruitment event.
                </p>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="poster">
              <img src={multiaward} alt="Location Unknown" />
            </div>
            <div className="details">
              <div className="rating text-center">
                MULTI-AWARDED RECRUITMENT AGENCY
              </div>
              <div className="bg-container">
                <div className="cast">
                  MULTI-AWARDED RECRUITMENT AGENCY FOR OVER 5O YEARS
                </div>
                <p className="desc">
                  IPAMS has been a consistent recipient of awards since the
                  beginning of the Central Bank (CB) and the Philippine Overseas
                  Employment Administration (POEA) award programs, now called
                  Department of Migrant Workers (DMW). IPAMS' commitment to
                  excel in business ethics and honorable practice has been
                  fundamental in its striking success throughout over five
                  decades of service.{" "}
                  <Link className="badge badge-primary text-white" to="/awards">
                    Read more
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="poster">
              <img src={roster} alt="Location Unknown" />
            </div>
            <div className="details">
              <div className="rating text-center">
                ROSTER OF INDUSTRY-LEADING CLIENTS
              </div>
              <div className="bg-container">
                <div className="cast">
                  ROSTER OF WORLD-RENOWNED INDUSTRY-LEADING CLIENTS
                </div>
                <p className="desc">
                  Our employers are leading companies that offer outstanding
                  employment opportunities for Filipinos. Therefore, we commit
                  ourselves to provide focused attention to our partner
                  employers and the job openings posted on our platform.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card-container">
            <div className="poster">
              <img src={e2erecruit} alt="Location Unknown" />
            </div>
            <div className="details">
              <div className="rating text-center">END-TO-END RECRUITMENT</div>
              <div className="bg-container">
                <div className="cast">END-TO-END RECRUITMENT</div>
                <p className="desc">
                  Successful on-boarding is our goal. We only line up quality
                  candidates for our esteemed employers and a proactive approach
                  is taken to source the right fit for the job. Our recruitment
                  is “full cycle,” encompassing the complete recruiting process
                  from conception to execution to post deployment employee
                  relations when our workers are in the job sites.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row justify-content-sm-center text-lg-left">
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={trustedbyfil}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              TRUSTED BY FILIPINOS; 100% NO FEE CHARGING COMPANY
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={trustedbygov}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              TRUSTED BY THE PHILIPPINE GOVERNMENT
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={facility}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              BEST-IN-CLASS FACILITIES FOR SMALL TO LARGE-SCALE RECRUITMENT
              ACTIVITIES
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                ``
                <img
                  className="mx-auto mt-5"
                  src={leadTech}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              LEADING TECHNOLOGY & STREAMLINED PROCESS{" "}
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={multiawards}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              MULTI-AWARDED RECRUITMENT AGENCY FOR OVER 5O YEARS{" "}
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={verifiedemployer}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              ROSTER OF HIGH-QUALITY INDUSTRY-LEADING CLIENTS{" "}
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={endtoend}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center ">
              END-TO-END RECRUITMENT{" "}
            </b>
          </div>
          <div className="col-md-12 col-lg-12 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left mt-5">
              <div className="mx-auto">
                <Link to="/aboutus" className="btn btn-primary">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </Fragment>
    );
  }
}

export default WhyPeopleChooseUs;
